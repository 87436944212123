import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Components
import FadeIn from '../Helpers/FadeIn';

// Styles
import * as styles from './styles/HeaderStyles';

// Images
import Eagle from './../../assets/eagle.svg';
import NewCourt from './../../assets/new_court.svg';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import NavBar from '../Helpers/NavBar';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
     {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Set-up Worker
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>£12/hr</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This role involves helping to set up the college before the ball starts,
with roles including, but not limited to, distributing tables and chairs, and moving
punts. More generally, heavy lifting is often required from workers in this position.
          </Typography>
        </AccordionDetails>
      </Accordion>*/}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Ticketing Worker</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
          £13.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This role involves assisting the Head of Ticketing in the
admission of all our guests, with tasks including, but not limited to, scanning tickets,
handing out programmes and directing guests to their allocated queuing areas.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          General Worker
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
          £13.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          General workers perform a variety of roles on the night to support
the Committee, including maintaining the cleanliness and aesthetics of the assigned
court, glass washing, manning ticketing queues, and facilitating the smooth running of
the ball throughout the night. Workers might be rotated through different roles.
Workers applying for this role may be allocated as Food or Drinks specific workers;
please mention any prior experience you may have in this area e.g. bar or catering
work.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Court Supervisor (including Cripps)</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £15.80-17/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Court supervisors are responsible for the
workers in their court, and oversee their jobs while reporting to the Heads of
Employment via radio. They are also expected to help manage the resources in their
court and report any important issues if necessary. Experience is preferred.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Clear-up Supervisor</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £15.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Clear-up supervisors oversee the clear-up workers, help to
guarantee that the process follows the planned schedule, and ensure that the worker
shift change-over runs smoothly. They must also communicate with Heads of
Employment and other Committee members
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Head of Mobile Workers</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £17/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The Head of Mobile Workers is in charge of coordinating
mobile workers during the night, moving them around the courts depending on where
help is needed.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Mobile Worker</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £14.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Mobile workers are responsible for responding in a timely manner
to any emergency situation (non-security related) which may arise during the ball,
such as spills or breakages. They must also show a good degree of adaptability, as
they will be redeployed multiple times during the night to assist in roles carried out by
general workers. Among other things, they could be in charge of checking wristbands,
crowd control, ushering people to fireworks, monitoring and helping with queues that
may occur.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/*<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Radio Control</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £13/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The radio operator sits in the radio control room monitoring for any
messages that need to be communicated and/or assisting with any events requiring
coordination of the security and ambulance services.
          </Typography>
        </AccordionDetails>
      </Accordion>*/}
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Bar Manager</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £15.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The May Ball features multiple student-run bars, each needing a
manager to direct “junior” student workers. Operations overseen by the bar manager
are not limited to the preparation of cocktails, but pertain to the smooth running of the
student bar as a whole. Previous bar experience is required for this position.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Ents Runner Supervisor</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £14.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Ents Runners are in charge of facilitating the smooth operations with
all ents from the moment they enter the ball to the point of finish. Tasks may include,
but are not limited to, taking the act to their set location, setting up and adjusting
musical equipment, liaising with court supervisors to tackle ents-related issues.
Experience in the musical industry is preferred. This role involves overseeing the
other ents runners and ensuring that they are fulfilling their duties.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Ents Runner</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
          £13.80/hr
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Ents Runners are in charge of facilitating the smooth operations with
all ents from the moment they enter the ball to the point of finish. Tasks may include,
but are not limited to, taking the act to their set location, setting up and adjusting
musical equipment, liaising with court supervisors to tackle ents-related issues.
Experience in the musical industry is preferred.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const Header: React.FC = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return <>
    <styles.Section>
          <NavBar />

      <styles.TextWrapper style={{marginBottom: "2rem"}}>
        <styles.Title><span>W</span>ork  <span>A</span>t  <span>T</span>he <span>B</span>all</styles.Title>
        <styles.Subtitle style={{fontSize: "150%"}}>All roles entail the right to buy a ticket to the 2026 May Ball.</styles.Subtitle>
        <styles.Subtitle style={{fontSize: "100%"}}> Full information about shifts and wages can be found on the <a target="_black" href="https://docs.google.com/forms/d/e/1FAIpQLSf89_VAEbhes7ywaRsoirOV9ZsKU88GWnnie2lkohQ0AfRyPA/viewform">application form</a>.</styles.Subtitle>
        <styles.Subtitle style={{fontSize: "100%"}}> A copy of the role descriptions can be found <a target="_black" href="https://drive.google.com/file/d/1yH93xpnpx4VDXkccPpCGVBK3nalka6nH/view">here</a>.</styles.Subtitle>
      </styles.TextWrapper>
      <styles.TextWrapper style={{margin: 0}}>
        <FadeIn triggered={true}>
        <ControlledAccordions />
          <styles.Space></styles.Space>
          <styles.TicketingLink><a target="_black" href="https://docs.google.com/forms/d/e/1FAIpQLSf89_VAEbhes7ywaRsoirOV9ZsKU88GWnnie2lkohQ0AfRyPA/viewform">Apply to work at the ball</a></styles.TicketingLink>
          {/*<styles.Space></styles.Space>
          <styles.TicketingLink><a target="_black" href="https://docs.google.com/forms/d/e/1FAIpQLSfFpjJ0LZwqED5uwIuSdrxx8xfBrfx1gW6dl9glfyrR4-RpBg/viewform?usp=sf_link">Apply to work as an Ents Runner</a></styles.TicketingLink>
          */}<styles.Space></styles.Space>
          <p></p>
        </FadeIn>
      </styles.TextWrapper>
    </styles.Section>
  </>
}

export default Header;
