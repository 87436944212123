import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Components
import FadeIn from '../Helpers/FadeIn';

// Styles
import * as HomepageStyles from './styles/HomepageStyles';
import * as styles from './styles/WorkStyles';

const Sponsor = () => {

  const [fadeInAnimate, setFadeInAnimate] = useState(false);

  return <>

    <styles.Section>
      <styles.BackgroundWrapper>
        <styles.BackgroundImage backgroundImage="/assets/img/poster.jpg"></styles.BackgroundImage>
        <styles.BackgroundGradient></styles.BackgroundGradient>
        <HomepageStyles.MainWrapper width="70%" style={{paddingTop: 200}}>
          <HomepageStyles.WideColumn>
            <styles.TripleColumn>
              <FadeIn triggered={fadeInAnimate}><styles.Column>
                  <VisibilitySensor partialVisibility={true} onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
                    <styles.Box>
                      <styles.BoxImageContainer backgroundColor='#1d2647'>
                        <img src="/assets/2024/jimmys.png" height="100px" style={{ margin: "10px" }}></img>
                      </styles.BoxImageContainer>
                      <styles.BoxInformation>
                        <styles.BoxTitle>OUR 2025 CHARITIES</styles.BoxTitle>
                        <styles.BoxEmphasisTitle>Jimmy's Cambridge</styles.BoxEmphasisTitle>
                        <styles.BoxBody>
                          <p>Jimmy’s is a community-based charity providing both support and housing to people who are rough sleeping. Part of your ticket purchase will be donated to support their work.</p>
                          <p>
                            Check them out <a style={{textDecoration: "underline"}} href="https://www.jimmyscambridge.org.uk/">online</a>.
                          </p>
                        </styles.BoxBody>
                      </styles.BoxInformation>
                    </styles.Box>
                  </VisibilitySensor>
                </styles.Column>
                <styles.Column>
                  <VisibilitySensor partialVisibility={true} onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
                    <styles.Box>
                      <styles.BoxImageContainer backgroundColor='#1d2647'>
                        <img src="/assets/2024/mind.png" height="100px" style={{ borderRadius: "0%", background: "white", margin: "10px" }}></img>
                      </styles.BoxImageContainer>
                      <styles.BoxInformation>
                        <styles.BoxTitle>OUR 2025 CHARITIES</styles.BoxTitle>
                        <styles.BoxEmphasisTitle>Mind</styles.BoxEmphasisTitle>
                        <styles.BoxBody><p>Mind is a charity that gives advice and support to empower anyone experiencing a mental health problem. They campaign to improve services, raise awareness and promote understanding.</p>
                          
                          <p>
                            Check them out <a style={{textDecoration: "underline"}} href="https://www.mind.org.uk/">online</a>.
                          </p>
                        </styles.BoxBody>
                      </styles.BoxInformation>
                    </styles.Box>
                  </VisibilitySensor>
                </styles.Column>
                <styles.Column>
                  <VisibilitySensor partialVisibility={true} onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
                    <styles.Box>
                      <styles.BoxImageContainer backgroundColor='#1d2647'>
                        <img src="/assets/2024/eu.png" height="100px" style={{ borderRadius: "0%", margin: "10px" }}></img>
                      </styles.BoxImageContainer>
                      <styles.BoxInformation>
                        <styles.BoxTitle>OUR 2025 CHARITIES</styles.BoxTitle>
                        <styles.BoxEmphasisTitle>Electric Umbrella</styles.BoxEmphasisTitle>
                        <styles.BoxBody>
                          <p> 
                            Electric Umbrella is a charity that uses music to bring people together, to create a sense of belonging and to make the world a better place. They are a community of rockstars and their mission is to empower and enrich the lives of adults with learning disabilities through music.
                          </p>
                          <p>
                            Check them out <a style={{textDecoration: "underline"}} href="https://electricumbrella.org.uk">online</a>.
                          </p>
                        </styles.BoxBody>
                      </styles.BoxInformation>
                    </styles.Box>
                  </VisibilitySensor>
                </styles.Column>
              </FadeIn>
            </styles.TripleColumn>
            <styles.TwoColumn  style={{marginTop: 50, marginBottom: 50}}>
              <FadeIn triggered={fadeInAnimate}>
                <styles.Column>
                  <VisibilitySensor partialVisibility={true} onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
                    <styles.Box>
                      <styles.BoxImageContainer backgroundColor='#1d2647'>
                        <img src="/assets/2024/accessaball.png" height="400px" style={{ borderRadius: "50%", margin: "10px" }}></img>
                      </styles.BoxImageContainer>
                      <styles.BoxInformation>
                        <styles.BoxTitle>OUR ACHIEVEMENTS</styles.BoxTitle>
                        <styles.BoxEmphasisTitle>Access-a-ball Platinum</styles.BoxEmphasisTitle>
                        <styles.BoxBody>
                          <p>
                            We are proud to be certified as Platinum for accessibility by Access-a-ball.
                            </p>
                        </styles.BoxBody>
                      </styles.BoxInformation>
                    </styles.Box>
                  </VisibilitySensor>
                </styles.Column>
                <styles.Column>
                  <VisibilitySensor partialVisibility={true} onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
                    <styles.Box>
                      <styles.BoxImageContainer backgroundColor='#1d2647'>
                        <img src="/assets/2024/sustainaball.jpeg" height="400px" style={{ borderRadius: "50%", margin: "10px" }}></img>
                      </styles.BoxImageContainer>
                      <styles.BoxInformation>
                        <styles.BoxTitle>OUR ACHIEVEMENTS</styles.BoxTitle>
                        <styles.BoxEmphasisTitle>Sustain-a-ball Platinum</styles.BoxEmphasisTitle>
                        <styles.BoxBody>
                          <p>
                            We are proud to be certified as Platinum by Sustain-a-ball.
                            </p>
                        </styles.BoxBody>
                      </styles.BoxInformation>
                    </styles.Box>
                  </VisibilitySensor>
                </styles.Column>
              </FadeIn>
            </styles.TwoColumn>
          </HomepageStyles.WideColumn>
        </HomepageStyles.MainWrapper>
      </styles.BackgroundWrapper>
    </styles.Section>
  </>
}

export default Sponsor;
