import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Components
import FadeIn from './../Helpers/FadeIn';

// Styles
import * as HomepageStyles from './styles/HomepageStyles';
import * as styles from './styles/WorkStyles';

const Work = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    autoplay: true,
    slidesToShow: 2.9,
    speed: 500
  };

  const [fadeInAnimate, setFadeInAnimate] = useState(false);



  return <>
    
      <styles.Section>
        <styles.BackgroundWrapper>
          <styles.BackgroundImage backgroundImage="/assets/img/new_court_sjc.jpg"></styles.BackgroundImage>
          <styles.BackgroundGradient></styles.BackgroundGradient>
          <HomepageStyles.MainWrapper width="50%">
                  <VisibilitySensor partialVisibility={true} onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
            <HomepageStyles.WideColumn>
              <styles.OneColumn>
                <FadeIn triggered={fadeInAnimate}>
                <styles.Column>
                  <styles.Box>
                    <styles.BoxImage backgroundImage={'/assets/img/employment.jpg'}></styles.BoxImage>
                    <styles.BoxInformation>
                      <styles.BoxTitle>EMPLOYMENT</styles.BoxTitle>
                      <styles.BoxEmphasisTitle>Work at the 2025 ball</styles.BoxEmphasisTitle>
                      {/*<styles.BoxBody>Worker applications for the 2025 May Ball have closed, but you can join our waitlist <a href="https://docs.google.com/forms/d/1pCcygt3JN19UcXBDa5Yab3So1yRwI1hik1j1NhQCj40/viewform?edit_requested=true">here</a>.</styles.BoxBody>*/}
                      <styles.BoxBody>We are looking for dedicated individuals who want to be involved in a variety of roles including set-up, hospitality and clear-up.</styles.BoxBody>
                      <styles.BoxBody>In return, you’ll get the chance to be involved with planning one of the world’s most desirable parties, fair pay and a right-to-buy ticket for the 2026 ball.</styles.BoxBody>
                      <styles.BoxBody>For any specific queries, please contact <a href="mailto:employment@mayball.org">employment@mayball.org</a>.</styles.BoxBody>
                      <styles.BoxButton href="https://docs.google.com/forms/d/e/1FAIpQLSf89_VAEbhes7ywaRsoirOV9ZsKU88GWnnie2lkohQ0AfRyPA/viewform?usp=sharing">Apply for jobs here</styles.BoxButton>
                    </styles.BoxInformation>
                  </styles.Box>
                </styles.Column>
                {/**<styles.Column>
                  <styles.Box>
                    <styles.BoxImage backgroundImage={'/assets/img/fireworks.jpg'}></styles.BoxImage>
                    <styles.BoxInformation>
                      <styles.BoxTitle>SPONSORSHIP</styles.BoxTitle>
                      <styles.BoxEmphasisTitle>Sponsor the 2023 ball</styles.BoxEmphasisTitle>
                      <styles.BoxBody>The St John's May Ball is one of the largest and most prominent balls in Cambridge and is looking to collaborate with businesses, charities, and individuals that are eager to deliver a fantastic guest experience.</styles.BoxBody>
                      <styles.BoxBody>If you would like to work with the ball then please get in contact with us to discuss possible sponsorship opportunities. All sponsorship arrangements are bespoke, and if you would like to find out more, please get in touch with Skye at <a href="mailto: sponsorship@mayball.org">sponsorship@mayball.org</a>. </styles.BoxBody>
                      <styles.BoxButton href="mailto:sponsorship@mayball.org">Get in touch</styles.BoxButton>
                    </styles.BoxInformation>
                  </styles.Box>
                </styles.Column> */}
                </FadeIn>
              </styles.OneColumn>
            </HomepageStyles.WideColumn>
            </VisibilitySensor>
          </HomepageStyles.MainWrapper>
        </styles.BackgroundWrapper>
      </styles.Section>
  </>
}

export default Work;
