import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Components
import FadeIn from './../Helpers/FadeIn';

// Styles
import * as HomepageStyles from './styles/HomepageStyles';
import * as styles from './styles/CommitteeStyles';

interface NameProps {
  name: string
  role: string
  email: string
  photo?: string[]
}

const Name:React.FC<NameProps> = ({name, role, email, email2, photo, ...props}) => {
  let emailFixed = email.includes("@") ? email : email + "@mayball.org"
  let emailFixed2 = email2 && email2.includes("@") ? email2 : email2 + "@mayball.org"
  return <>
    <styles.NameWrapper>
    {/*<styles.PhotoGroup>{photo && photo.map((x) => <styles.Photo><img src={`/assets/headshots/${x}.jpeg`} /></styles.Photo>)}</styles.PhotoGroup>*/}
      <styles.Role>{role}</styles.Role>
      <styles.Name>{name}</styles.Name>
      {email.length > 0 && <styles.Email><a href={`mailto:${emailFixed}}`}>{`${emailFixed}`}</a></styles.Email>}
      {email2 && email2.length > 0 && <styles.Email><a href={`mailto:${emailFixed2}}`}>{`${emailFixed2}`}</a></styles.Email>}
    </styles.NameWrapper>
  </>
}

const Committee = () => {
  const [fadeInAnimate, setFadeInAnimate] = useState(false);

  return <>
    <styles.Section>
      <HomepageStyles.MainWrapper width={'70%'}>
          <styles.WideColumn>
            <VisibilitySensor onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
              <HomepageStyles.SectionTitle>MEET THE COMMITTEE</HomepageStyles.SectionTitle>
            </VisibilitySensor>
            <HomepageStyles.EmphasisText>The 2025 Ball Committee</HomepageStyles.EmphasisText>
            
            <FadeIn triggered={fadeInAnimate}>
              <styles.SubCommBlock>Exec committee</styles.SubCommBlock>
              <styles.NameBlock>
                <Name name="Oskar Walker" role="President" email="president" photo={["dommy"]} />
              </styles.NameBlock>
              <styles.NameBlock spacerBelow>
              <Name name="Michele Bolla" role="Vice President - Operations" email="vp-operations" photo={["minaam"]} />
                <Name name="Marina Rodriguez Lopez" role="Vice President - Guest Experience" email="vp-guestexperience" photo={["minaam"]} />
               
              </styles.NameBlock>
              <styles.NameBlock>
                <Name name="Ryan Hu" role="Junior Treasurer & Vice President" email="treasurer" photo={["anna"]} />
                <Name name="José Luis Narbona Valiente" role="Assistant Treasurer" email="assistant-treasurer" photo={["jerry"]} />
              </styles.NameBlock>
              <styles.SubCommBlock>Operations</styles.SubCommBlock>
              <styles.NameBlock>
                <Name name="Souradip Mookerjee" role="Technology" email="technology" photo={["souradip"]} />
                <Name name="Alex Massucco" role="Head of Logistics" email="logistics" photo={["leo"]} />
                <Name name="Carla Biermann" role="Deputy Head of Logistics" email="logistics" photo={["leo"]} />
                <Name name="K-M White" role="Security and Accessibility" email="security" photo={["julia"]} />
              </styles.NameBlock>
              <styles.NameBlock>
                <Name name="Kittson Hamill" role="Head of Employment" email="employment" photo={["shruti"]} />
                <Name name="Scarlett Parker" role="Deputy Head of Employment" email="employment" photo={["ming"]} />
                <Name name="Ellie Vitanov" role="Head of Ticketing" email="tickets" photo={["minaam"]} />
              </styles.NameBlock>
              <styles.SubCommBlock>Guest experience</styles.SubCommBlock>
              <styles.NameBlock>
                <Name name="Rhiannon Llystyn Jones" role="Co-Head of Ents" email="ents" photo={["chantelle"]} />
                <Name name="George Akka" role="Co-Head of Ents" email="ents" />
                <Name name="John Chang" role="Head of Food" email="food"  />
              </styles.NameBlock>
              <styles.NameBlock>
                <Name name="Talia Kertsman" role="Head of Drinks" email="drink" photo={["max"]} />
                <Name name="Amanda Ljungberg" role="Co-Head of Scene" email="scene" photo={["liv"]} />
                <Name name="Soleh Anderlini" role="Co-Head of Scene" email="scene" photo={["liv"]} />
              </styles.NameBlock>
              <styles.NameBlock>
                <Name name="Lily Pfaffenzeller" role="Head of Creative" email="creative" photo={["shruti"]} />
                <Name name="Libby Reid" role="Head of Social Media and Marketing" email="creative" photo={["shruti"]} />
              </styles.NameBlock>
              <styles.SubCommBlock>Other contacts</styles.SubCommBlock>
              <styles.NameBlock>
                <Name name="Emike Enaife" role="Head of Sponsorship and Sustainability" email="sustainability" photo={["john"]} />
              </styles.NameBlock>
              <styles.NameBlock>
                <Name name="Dorian Gangloff" role="Chair (Fellow)" email="dag50@cam.ac.uk" photo={["graeme"]} />
                <Name name="Nathan MacDonald" role="Senior Treasurer (Fellow)" email="nm10011@cam.ac.uk" photo={["graeme"]} />
              </styles.NameBlock>
            </FadeIn>
          </styles.WideColumn>
        </HomepageStyles.MainWrapper>
    </styles.Section>
  </>
}

export default Committee;
